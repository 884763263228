import React, { useEffect, useState } from 'react';
import dbicon from '../../assets/cardImage2.PNG';
import cardImage from '../../assets/cardImage.JPG';
import {Button} from "react-bootstrap";
import axios from 'axios';
import {
    Card,
    CardTitle,
    CardImage,
    CardBody,
} from "@progress/kendo-react-layout";

  const Portal = ({setCurrentApp, ...rest}) => {

    let [apps, setApps] = useState([]);
    
    useEffect(() => {
      axios.post("/php/calldb.php", "action=getapps&scope=user",  {
        headers: { 
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      } )
        .then(function(response) {
          const data = response.data.data;
          data.pop();
          
          setApps(data.map( (app) => { 
            const result = {};
            result.headerTitle = app.title;
            result.headerSubtitle = app.iscore === "1"?'Core Database':'';
            result.iconUrl = app.iscore === "1"?dbicon:cardImage;
            result.appId = app.id;
            result.appUrl = `/dcom2.php?appId=${app.id}`;
            return result;
           }));
                     
          }).catch(function(error) {
            console.log(error);
        })
    }, []);
    
    return (

      <div className="dcom-portal-body">
       <span className="dcom-portal-search">
            <input type="text" className="form-control" placeholder="Search Dcom"/>
            <Button className="dcom-search-button" variant="primary">Search</Button>
       </span>
          <div
              style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
              }}
          >
              {apps.map((app, index) => {
                  return (
                      <div key={index}>
                          <a href='#' onClick={() => setCurrentApp(app)} style={{
                              color: "black",
                              "text-decoration": "none"
                          }}>
                              <Card className="dcom-card">
                                  <CardImage src={app.iconUrl}/>
                                  <div>
                                      <CardTitle className="dcom-card-title">
                                          {app.headerTitle || ""}
                                      </CardTitle>
                                      <CardBody>
                                          <p className="dcom-card-description">
                                              {app.headerSubtitle ||
                                              " "}
                                          </p>
                                      </CardBody>
                                  </div>
                              </Card>
                          </a>
                      </div>
                  );
              })}
          </div>
      </div>
  );
}

export default Portal;
