import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import settingsImage from "../assets/settings.png";
import passwordImage from "../assets/pass.png";

import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";

import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
let kendokaAvatar =
  "https://www.telerik.com/kendo-react-ui-develop/images/kendoka-react.png";

const ApplicationTopBar = ({ content, appLogout, ...rest }) => {
  function openAdminPanel() {
    window.open('admin','admin');
  }

  return (
    <AppBar className="Dcom-app-bar" themeColor="primary">
      <AppBarSection
        style={{
          width: "320px",
          display: "grid",
          backgroundColor: "#00205b",
          color: "#fff",
          height: "50px",
        }}
      >
        {/*<h2 style={{marginBottom:0}} className="title">Dcom</h2>*/}
        <span className="title">Dcom</span>
      </AppBarSection>

      <AppBarSpacer style={{ width: 4 }} />

      <AppBarSection>
        <img
          id="loadingIcon"
          class="dComLeftHeader"
          style={{
            "padding-left": "0px",
            visibility: "hidden",
            display: "inline-block",
            height: "25px",
            width: "25px",
          }}
          src="assets/loading.gif"
        />
      </AppBarSection>
      <AppBarSpacer style={{ width: 4 }} />
      <AppBarSpacer />
      <AppBarSection>
        <img
          class="dComRightHeader"
          id="passwordManagement"
          href="https://demo.rivit.ca/DCOM%20User%20Guide.pdf"
          style={{
            margin: "5px",
            display: "inline-block",
            height: "26px",
            cursor: "pointer",
          }}
          src={passwordImage}
        />

        { window.name !== 'admin' && <img
          class="dComRightHeader"
          id="settingsLink"
          style={{
            margin: "5px",
            display: "inline-block",
            height: "26px",
            cursor: "pointer",
          }}
          src={settingsImage}
          onClick={openAdminPanel}
        />}
      </AppBarSection>

      <AppBarSpacer style={{ width: 4 }} />
      <AppBarSection>
        <span
          id="logoutLink"
          style={{
            cursor: "pointer",
            display: "inline-block",
          }}
          onClick={appLogout}
        >
          Logout
        </span>
        &nbsp;&nbsp;
        <div
          id="help_button"
          style={{
            display: "inline-block",
          }}
        />
      </AppBarSection>
    </AppBar>
  );
};

export default ApplicationTopBar;
