import React, { useEffect, useState } from 'react';
import usePersistedState from './usePersistedState';
import Portal from './portal/Portal.jsx';
import DatabaseView from './database_view/DatabaseView.jsx';
import './styles.css';

const ApplicationLayout = () => {
 
    let [isPortalHidden, hidePortal] = usePersistedState('isPortalHidden',false);
    let [currentApp, setCurrentApp] = usePersistedState('currentApp', null);

    function showDatabase(app) {
       setCurrentApp(app);
       hidePortal(true);
    }
    
    function showPortal() {
      hidePortal(false);
    }

    return (
      <React.Fragment>
        { !isPortalHidden && <Portal setCurrentApp={showDatabase}></Portal> }
        { isPortalHidden && <DatabaseView currentApp={currentApp} showPortal={showPortal}></DatabaseView> }
      </React.Fragment>
    );
}

export default ApplicationLayout;
