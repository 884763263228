import React, { useEffect, useState } from "react";
import "./styles.css";
import axios from "axios";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import PanelBarNavContainer from "./PanelBarNavContainer";
import UserSettings from "./UserSettings";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";
import GroupSettings from "./GroupSettings";
import About from "./About";
import Application from "./Application";
import Index from "./Index";

const AdminPanel = ({showPortal, ...rest}) => {
  const [selectedSetting, setSelectedSetting] = useState("Users");

  const settings = ["Users", "Groups", "Applications", "Index", "AboutDcom"];

  switch (selectedSetting) {
    case "Users":
      break;
    case "Groups":
      break;
    case "Applications":
      break;
    case "index":
      break;
    case "AboutDcom":
      break;
    default:
      break;
  }

  const handleSelect = (e) => {
    if (e.target.props.title !== "Settings")
      setSelectedSetting(e.target.props.title);
  };

  return (
    <>
      <div className="dcom-admin-wrapper" style={{ height: "100%" }}>
        <div
          style={{ width: "100%", backgroundColor: "#FAFAFD", height: "100%" }}
        >
          <HashRouter>
            <PanelBarNavContainer showPortal={showPortal}>
              <Switch>
                <Route exact={true} path="/extra" component={UserSettings} />
                <Route exact={true} path="/" component={UserSettings} />
                <Route exact={true} path="/groups" component={GroupSettings} />
                <Route exact={true} path="/about" component={About} />
                <Route
                  exact={true}
                  path="/application"
                  component={Application}
                />
                <Route exact={true} path="/index" component={Index} />
              </Switch>
            </PanelBarNavContainer>
          </HashRouter>
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
