import React, { useEffect, useState } from "react";
import { getter } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Row, Col } from "react-bootstrap";

import {
  Grid,
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import axios from "axios";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { getAllGroups, addGroupToGroup } from "./ApiCalls";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function GroupSettings(props) {
  let [allGroups, setAllGroups] = useState({ data: [] });
  let [allGroupsName, setAllGroupsName] = useState({ data: [] });

  let [groupMember, setGroupMember] = useState([]);
  let [groupsGroup, setGroupsGroup] = useState([]);

  let [isForm, setIsForm] = useState(false);
  let [addToGroup, setAddtoGroup] = useState(false);

  //Grid selection hooks
  const [selectedState, setSelectedState] = React.useState({});

  let [notification, setNotification] = useState(false);

  let [notificationMsg, setNotificationMsg] = useState("Attention !");
  let [notificationType, setNotificationType] = useState("Success");

  let [groupId, setGroupId] = useState({});
  let [selectedGroupId, setSelectedGroupId] = useState(0);

  let [isCreateNew, setCreateNew] = useState(false);

  const [visible, setVisible] = React.useState(false);

  const [group, setGroup] = React.useState({
    groupName: "",
    groupDesc: "",
  });

  const toggleDialog = () => {
    setVisible(!visible);
  };

  function toggleAddToGroup() {
    setAddtoGroup(!addToGroup);
  }

  // Function to show notification that runs for 8 secs.
  function showNotification(message, notificationType) {
    setNotificationType(notificationType);
    setNotificationMsg(message);
    setNotification(!notification);
    if (!notification) {
      setTimeout(() => {
        setNotification(false);
      }, 8000);
    }
  }

  useEffect(() => {
    getGroups();
  }, []);

  // Function to handle grid List clicks
  function handleGridRowClick(e) {
    setIsForm(true);
    setGroup({
      groupName: e.dataItem.groupname,
      groupDesc: e.dataItem.groupdesc,
    });

    setGroupId(e.dataItem.id);
    if (e.dataItem.id) {
      getUserGroup(e.dataItem.id);
      getGroupsGroup(e.dataItem.id);
    }
  }

  // Function to getAll Groups
  function getGroups() {
    getAllGroups((response) => {
      setAllGroups(response);
      console.log(response.data);
      let groupsName = response.data
        .filter((group) => group && group.groupname)
        .map((group) => group.groupname);
      groupsName.sort();
      setAllGroupsName(groupsName);
    });
  }

  // Dropdown that handles add Group to Members
  function handleDropdownChange(e) {
    getAllGroups((response) => {
      let groups = response.data.filter(
        (group) => group && group.groupname === e.target.value
      );
      setSelectedGroupId(groups && groups[0]?.id);
    });
  }

  // Function to Create Users initiated from update user button
  function getUserGroup(groupId) {
    axios
      .post("/php/calldb.php", `action=getmembers&groupid=${groupId}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        console.log("response.data", response.data);

        const data = response.data.data;
        setGroupMember(data);
        console.log("getGroupMember -> ", data);
      });
  }

  // Function to Create Users initiated from update user button
  function getGroupsGroup(groupId) {
    axios
      .post("/php/calldb.php", `action=getgroupgroups&groupid=${groupId}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        const data = response.data.data;
        setGroupsGroup(data);
        console.log("getGroupsGroup -> ", data);
      });
  }

  // Function to Create Users initiated from handleSubmit
  function createGroup(groupName, groupDesc) {
    axios
      .post(
        "/php/calldb.php",
        `action=creategroup&groupname=${groupName}&password=${groupDesc}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then(function (response) {
        const data = response.data;
        if (data.status === "success") {
          showNotification("Group created Successfully !", "success");
          getGroups();
          handleBack();
        } else {
          // alert(`Status : ${data.status}, Message:${data.message}`)
          showNotification(" Error: " + data.message, "error");
          handleBack();
        }
      });
  }

  // Function to Delete group (getInitiated after Yes is pressed on the dialogue)
  function deleteGroup() {
    setVisible(!visible);
    if (groupId) {
      axios
        .post("/php/calldb.php", `action=deletegroup&groupid=${groupId}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then(function (response) {
          const data = response.data;
          if (data.status === "success") {
            // alert(`Status : ${data.status} !`)
            getGroups();
            showNotification(`Group successfully Deleted`, "success");
            handleBack();
          } else {
            // alert(`Status : ${data.status}, Message:${data.message}`)
            showNotification(
              `Status : ${data.status} , Message: ${data.message}`,
              "error"
            );
            handleBack();
          }
        });
    }
  }

  // Submit button of the Create user form
  const handleSubmit = (dataItem) => {
    createGroup(dataItem.groupName, dataItem.groupDesc);
  };

  //Handle New Group Button
  function handleNewGroup() {
    setCreateNew(true);
    setIsForm(true);
    setGroup({
      groupName: "",
      groupDesc: "",
    });
  }

  // Submit button of the Create user form
  const handleDelete = (username) => {
    if (groupId) deleteGroup();
  };

  // Handle ADD button for AddToGroup
  const addToGroupMemeber = () => {
    if (selectedGroupId && groupId) {
      addGroupToGroup(selectedGroupId, groupId, (result) => {
        if (result.status === "success") {
          getGroups();
          showNotification(`Group successfully added to Group !`, "success");
          handleBack();
        } else {
          showNotification(
            `Status : ${result.status} , Message: ${result.message}`,
            "error"
          );
          handleBack();
        }
      });
    }
  };
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const [page, setPage] = React.useState(initialDataState);

  const DATA_ITEM_KEY = "groupname";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  const pageChange = (event) => {
    setPage(event.page);
  };

  // Submit button of the Create user form
  const handleBack = () => {
    setIsForm(false);
    setCreateNew(false);
  };

  const handleRemoveFromGroup = () => {
    handleBack();
  };

  // selection of the grids on Click
  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  return (
    <div className="dcom-setting-body">
      <NotificationGroup
        style={{
          right: "10px",
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade enter={true} exit={true}>
          {notification && (
            <Notification
              type={{ style: `${notificationType}`, icon: true }}
              closable={true}
              onClose={() => setNotification(false)}
            >
              <span>{notificationMsg}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {visible && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Are you sure you want to delete the Group ?
          </p>
          <DialogActionsBar>
            <button className="k-button" onClick={toggleDialog}>
              No
            </button>
            <button className="k-button" onClick={handleDelete}>
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <h1 style={{ padding: "25px" }}>{props.title} Groups </h1>
      <div className="dcom-user-settings-wrapper">
        {isForm ? (
          <div className="dcom-user-form-wrapper">
            <div style={{ paddingLeft: 0 }} className="buttonWrapper">
              <button
                onClick={handleBack}
                className="k-button k-outline k-button-icontext dcom-button"
                type="button"
              >
                <span role="presentation"></span>Back
              </button>
            </div>
            <Form
              onSubmit={handleSubmit}
              initialValues={group}
              key={JSON.stringify(group)}
              className="dcom-user-form"
              render={(formRenderProps) => (
                <FormElement>
                  <fieldset className={"k-form-fieldset"}>
                    <legend className={"k-form-legend"}>
                      Please fill in the fields:
                    </legend>

                    <div className="row">
                      <div className="col">
                        {/* Group Name in the form */}
                        <div className="mb-4">
                          <Field
                            name={"groupName"}
                            component={Input}
                            label={"Group name"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        {/* Group Description in the form */}
                        <div className="mb-4">
                          <Field
                            name={"groupDesc"}
                            component={Input}
                            label={"Group Description"}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="k-form-buttons">
                    <button
                      type={"submit"}
                      className="k-button dcom-button"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {isCreateNew ? "Create Group" : "Update Group"}
                    </button>
                    {!isCreateNew && (
                      <button
                        className="k-button dcom-button"
                        onClick={toggleDialog}
                      >
                        Delete Group
                      </button>
                    )}
                  </div>
                </FormElement>
              )}
            />
            <hr />
            <Row>
              <Col>
                {!isCreateNew && (
                  <>
                    <h3>Groups Member</h3>
                    <Grid
                      style={{ maxHeight: "330px", marginTop: 57 }}
                      data={groupMember}
                    >
                      <Column field="member" title="Group Member" />
                      <Column field="memberType" title="Group Member Type" />
                    </Grid>
                  </>
                )}
              </Col>
              {/* Membership in Groups {with Add button to add a new group} */}
              <Col>
                {!isCreateNew && (
                  <>
                    <h3>Membership in Groups</h3>

                    <div className="k-form-buttons">
                      <button
                        type={"submit"}
                        className="k-button dcom-button-long"
                        onClick={toggleAddToGroup}
                      >
                        {addToGroup ? "Cancel" : "Add to Group"}
                      </button>
                      <button
                        type={"submit"}
                        className="k-button dcom-button-long"
                        onClick={handleBack}
                      >
                        Remove from Group
                      </button>
                    </div>
                    {addToGroup && (
                      <div className="k-form-buttons">
                        <DropDownList
                          style={{ marginTop: 10, maxWidth: 145 }}
                          className="gridHeaderFilter"
                          onChange={handleDropdownChange}
                          defaultItem={"Select Group . ."}
                          data={allGroupsName}
                        />
                        <button
                          onClick={addToGroupMemeber}
                          className="k-button dcom-button-long"
                        >
                          Add
                        </button>
                      </div>
                    )}
                    <Grid
                      style={{
                        maxHeight: "330px",
                        marginRight: 10,
                      }}
                      dataItemKey={DATA_ITEM_KEY}
                      selectedField={SELECTED_FIELD}
                      selectable={{
                        enabled: true,
                        mode: "single",
                      }}
                      data={groupsGroup.map((e) => ({
                        ...e,
                        [SELECTED_FIELD]: selectedState[idGetter(e)],
                      }))}
                      onSelectionChange={onSelectionChange}
                    >
                      <Column field="groupname" title="Group Member" />
                    </Grid>
                  </>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <div className="dcom-user-data-grid">
            <div style={{ paddingLeft: "25px" }} className="buttonWrapper">
              <button
                className="k-button k-outline k-button-icontext dcom-button"
                onClick={getGroups}
                type="button"
              >
                <span role="presentation"></span>Refresh
              </button>
              <button
                className="k-button k-outline k-button-icontext dcom-button"
                type="button"
                onClick={handleNewGroup}
              >
                <span role="presentation"></span>Create Group
              </button>
            </div>
            <Grid
              style={{
                maxHeight: "780px",
              }}
              onRowClick={handleGridRowClick}
              data={allGroups.data}
            >
              {/*<Column field="id" title="ID"  />*/}
              <Column field="groupname" title="Group Name" />
              <Column field="groupdesc" title="Group Description" />
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
