import React from 'react';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import ApplicationTopBar from './components/ApplicationTopBar';
import Login from './components/Login.jsx';
import usePersistedState from './components/usePersistedState';
import axios from 'axios';

const App = ({component}) => {
      const Component = component;
         
      let [isLoggedIn, setLoginState] = usePersistedState('isLoggedIn',false);

      function appLogout() {
            axios.get("/php/logout.php","")
              .then(function(response) {
                setLoginState(false);
                //hidePortal(false);
              }).catch(function(error) {
                  console.log(error);
              })
      }    

      if( isLoggedIn ) return ( 
            <React.Fragment>
            <ApplicationTopBar appLogout={appLogout} ></ApplicationTopBar>
            <Component></Component> 
            </React.Fragment>
      );
      else return (
            <Login setLoginState = {setLoginState} ></Login>
      );
}

export default App;
