import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPanel from './components/AdminPanel';
import ApplicationLayout from './components/ApplicationLayout';
import App from './App'
import './App.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/"><App component={ApplicationLayout}/></Route>
        <Route exact path="/admin"><App component={AdminPanel}/></Route>
      </Switch>
    </Router>,
  </React.StrictMode>,
  document.getElementById('root')
);


