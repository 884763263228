import React, { useEffect, useState, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Modal } from "react-bootstrap";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "react-bootstrap";
import add from "../assets/add.png";
import minus from "../assets/minus.png";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import clear from "../assets/ClearWhite.png";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";

const defaultCurrentRows = [];

export default function MainGrid({
  currentColumns: currCol = [],
  currentRows: currRow = defaultCurrentRows,
  updatedColumnData: upCol = [],
}) {
  // const {
  //   currentColumns: currCol = [],
  //   currentRows: currRow = [],
  //   updatedColumnData: upCol = [],
  // } = props;

  const [show, setShow] = useState(false);

  let [isInputEmpty, setIsInputEmpty] = useState(true);
  let [searchValue, setSearchValue] = useState("");
  let [showAdvanced, setShowAdvanced] = useState(false);
  let [showFilter, setShowFilter] = useState(false);

  const value = new Date();
  const advancedFilter1 = ["created", "modified"];
  const advancedFilter2 = [
    "is",
    "is not",
    "is before",
    "is after",
    "is between",
    "is not between",
  ];

  // - - - - - - -  GroupableCode Starts Here

  const [collapsedState, setCollapsedState] = React.useState([]);

  const initialGroup = upCol
    .filter((col) => col.iscategory === "True")
    .map((col) => ({ field: col.name }));

  const [resultState, setResultState] = React.useState([]);

  useEffect(() => {
    setResultState(processWithGroups(currRow, initialGroup));
  }, [currRow]);

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );
  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
      data: newDataState,
      group: group,
    });
    return newDataState;
  };

  // - - - - - - -  GroupableCode Ends Here

  //Clear text in input box
  function clearSearch() {
    setSearchValue("");
    setIsInputEmpty(true);
  }

  //toggle for Advanced Functions
  function showAdvancedFilter() {
    setShowAdvanced(!showAdvanced);
  }

  //toggle for  filter
  function showFilters() {
    setShowFilter(!showFilter);
  }

  //OnChange of Input Box
  function updateInputValue(e) {
    let changedValue = e.target.value;
    setSearchValue(e.target.value);
    if (changedValue.length !== 0) {
      setIsInputEmpty(false);
    } else {
      setIsInputEmpty(true);
    }
  }

  // Custom Cell for Icons
  const cellWithBackGround = (props) => {
    const item = props.field;
    return <td> {IconFormatter(props.dataItem[item])} </td>;
  };

  // Function to handle grid List clicks
  function handleGridRowClick(e) {
    setShow(true);
    console.log(e.dataItem);
  }

  // Function to output Icons in GridCells based on incoming values
  function IconFormatter(value) {
    return value > 0 && value < 213 ? (
      <img
        src={
          "../images/dicons/vwicn" +
          ("00" + value).substr(value.toString().length - 1) +
          ".gif"
        }
      />
    ) : (
      <span></span>
    );
  }

  // Function for generating Gridcolumn based on Current incoming column value
  function getCol(thisCol) {
    if (thisCol.iscategory === "True") return;
    console.log("Cols",thisCol)
    if (thisCol.isicon === "True")
      return (
        <GridColumn
          field={thisCol.name}
          title={thisCol.name}
          cell={cellWithBackGround}
          width="110px"
          filterable={false}
        />
      );
    return (
      <GridColumn field={thisCol.name} title={thisCol.name} width="150px" />
    );
  }

  const [filter, setFilter] = React.useState();
  return (
    <div>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Dcom</Modal.Title>
        </Modal.Header>
        <Modal.Body>PDF body content</Modal.Body>
      </Modal>

      {/* GridHeader Starts Here ----------------------------------- */}

      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <div className="row">
          <div
            style={{
              height: "55px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="col"
          >
            <div style={{ marginRight: "10px" }}>
              <img
                id="zoomIn"
                className="dComLeftHeader"
                style={{
                  display: "inline-block",
                  height: "15px",
                  width: "15px",
                }}
                src={add}
              />
              <img
                id="zoomOut"
                className="dComLeftHeader"
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  display: "inline-block",
                  height: "15px",
                  width: "15px",
                }}
                src={minus}
              />
            </div>
            <span className="dcom-grid-search">
              <input
                type="text"
                className="form-control"
                value={searchValue}
                onChange={updateInputValue}
                placeholder="Enter your query "
              />

              {/* Condition to check if Clear text input is required */}
              {!isInputEmpty && (
                <Button
                  className="dcom-search-button"
                  style={{
                    width: "45px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={clearSearch}
                  variant="danger"
                >
                  <img
                    id="zoomIn"
                    style={{ width: "18px" }}
                    className="dComLeftHeader"
                    src={clear}
                  />
                </Button>
              )}
              {/* Advanced Filters */}
              {showAdvanced && (
                <div>
                  <DropDownList
                    className="gridHeaderFilter"
                    defaultValue="created"
                    data={advancedFilter1}
                  />
                  <DropDownList
                    className="gridHeaderFilter"
                    defaultValue="is after"
                    data={advancedFilter2}
                  />
                  <DatePicker
                    className="gridHeaderFilter"
                    disabled={false}
                    defaultValue={value}
                    format="dd/MMMM/yyyy"
                  />
                </div>
              )}
              <Button className="dcom-search-button" variant="primary">
                Search
              </Button>
            </span>
            <div>
              <Button
                style={{
                  height: "30px",
                  fontSize: "14px",
                  maxWidth: "130px",
                  lineHeight: "14px",
                }}
                onClick={showAdvancedFilter}
                variant="primary"
              >
                {showAdvanced ? "Hide Advanced" : "Show Advanced"}
              </Button>

              <Button
                style={{
                  height: "30px",
                  fontSize: "14px",
                  maxWidth: "130px",
                  lineHeight: "14px",
                  marginLeft: 10,
                }}
                onClick={showFilters}
                variant="primary"
              >
                {showFilter ? "Hide Filter" : "Show Filter"}
              </Button>

              <Button
                style={{
                  marginLeft: "10px",
                  maxWidth: "120px",
                  height: "30px",
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
                variant="outline-success"
              >
                Export
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* GridHeader Ends Here ------------------------------------- */}

      <Grid
        style={{
          maxHeight: "800px",
        }}
        onRowClick={handleGridRowClick}
        // data={currRow}
        // data={newData}
        data={filterBy(newData, filter)}
        filterable={showFilter}
        filter={filter}
        // onGroupChange={onGroupChange}
        group={initialGroup}
        onExpandChange={onExpandChange}
        expandField="expanded"
        onFilterChange={(e) => setFilter(e.filter)}
      >
        {upCol.map(getCol)}
      </Grid>
    </div>
  );
}
