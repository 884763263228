import React, { useEffect, useState } from "react";
import usePersistedState from "../usePersistedState";
import axios from "axios";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import GridHeader from "../GridHeader";
import { useAsyncFocusBlur } from '@progress/kendo-react-common';
import { getCurrentGrid } from "../ApiCalls";
import MainGrid from "../MainGrid";

const DatabaseView = ( {currentApp,showPortal, ...rest}) => {

  const [rowData, setRowData] = React.useState();
  const [columnData, setColumnData] = React.useState();
  const [NewColumnData, setNewColumnData] = React.useState();
  
  let [viewUrl, setViewUrl] = usePersistedState("viewId", null);
  let [views, setViews] = useState([]);

  useEffect(() => {
    axios
      .post("/php/calldb.php", `action=getviews&appid=${currentApp.appId}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        const data = response.data.data;
        data.pop();

        const tree = {
          label: currentApp.headerTitle,
          items: data.map((item) => {
            return { label: item.text, id: item.id };
          }),
        };

        setViews(tree);
      });
  }, []);

  const onItemClick = (event) => {
    const viewId = event.target.props.customProp;
    if(viewId === 'back') showPortal();
    else {
        setViewUrl(`&viewId=${viewId}`);
        getGridData(viewId);
    }
  };

  // console.log("Views",views.items)

  // console.log("currentAppId",currentApp.appId)

  function getGridData(e) {
    getCurrentGrid(currentApp.appId, e, (response) => {
      var parseString = require("xml2js").parseString;
      var xml = response;
      parseString(xml.data, function (err, result) {
        const { view: { header = [] } = {} } = result;
        const { view: { row = [] } = {} } = result;

        console.log("xml2js", result);

        const columnNames = header[0].column.map((el) => el["$"].name);

        const retColData = header[0].column.map((currCol) => {
          const { name, type, isicon, iscategory } = currCol["$"];
          return { name, type, isicon, iscategory };
        });
        setNewColumnData(retColData);

        // header[0].column.map((currCol) => {
        //   let col = { "name": currCol["$"].name, "type": currCol["$"].type };
        //   setNewColumnData(...NewCcolumnData, col);
        // });

        setColumnData(columnNames);

        const rowDataNew = row.map((thisRow) =>
          thisRow.column.reduce(
            (previousValue, thisColumn, index) => ({
              ...previousValue,
              [columnNames[index]]: thisColumn["$"].value,
            }),
            {}
          )
        );

        // for (var i = 1; i < row.length; i++) {
        //   setRowData([...rowData, row[i].column.map((el) => el["$"].value)]);
        // }

        setRowData(rowDataNew);
        // Console Logs
        // console.log("GridValue", rowDataNew);
      });
    });
  }
  console.log(NewColumnData);

  if (views.items)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="dcom-portal-treeView">
          <PanelBar onSelect={onItemClick} className="dcom-side-menu">
            <PanelBarItem className="dcom-side-menu-item" title={'Back'} customProp={'back'}/>
            <PanelBarItem
              className="dcom-side-menu-item"
              title={views.label}
              expanded={true}
            >
              {views.items.map((e) => {
                return <PanelBarItem title={e.label} customProp={e.id} />;
              })}
            </PanelBarItem>
          </PanelBar>
        </div>
        <div style={{ overflow: "hidden", height: "100%" }}>
          {/* <GridHeader /> */}
          {/* <iframe
            style={{
              height: "100%",
              width: "100%",
              border: 0,
            }}
            src={`${currentApp.appUrl}${viewUrl}`}
          /> */}
          <MainGrid
            updatedColumnData={NewColumnData}
            currentRows={rowData}
            currentColumns={columnData}
          />
        </div>
      </div>
    );
  else return <div> LOADING DATA....</div>;
};

export default DatabaseView;
