import React, {useEffect, useState} from 'react'
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Input} from "@progress/kendo-react-inputs";
import {Grid} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import axios from "axios";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {getAllGroups, getAllUser, getAppAccessList} from "./ApiCalls";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";

export default function Application() {

    let [appMember, setAppMember] = useState([]);
    let [allApps, setAllApps] = useState({data:[]});
    let [accessMembers, setAccessMembers] = useState({data:[]});

    let [allUsers, setAllUsers] = useState({data:[]});
    let [allGroups, setAllGroups] = useState({data:[]});

    let [notification, setNotification] = useState(false);
    let [notificationMsg, setNotificationMsg] = useState("Attention !");
    let [notificationType, setNotificationType] = useState("Success");

    let [userList, setUserList] = useState([]);
    let [groupList, setGroupList] = useState([]);
    let [selectedAppId, setSelectedAppId] = useState(0);

    let [selectedGroupId, setSelectedGroupId] = useState(0);
    let [selectedUserId, setSelectedUserId] = useState(0);

    let [addGroup, setAddGroup] = useState(false);
    let [addUser, setAddUser] = useState(false);

    const initialDataState = {
        skip: 0,
        take: 10,
    };
    const [page, setPage] = React.useState(initialDataState);
    const pageChange = (event) => {
        setPage(event.page);
    };

    function toggleAddToGroup (){
        setAddGroup(!addGroup)
        setAddUser(false)
    }

    function toggleAddToUser (){
        setAddUser(!addUser)
        setAddGroup(false)
    }

    function showNotification(message, notificationType){
        setNotificationType(notificationType)
        setNotificationMsg(message)
        setNotification(!notification)
        if (!notification){
            setTimeout(() => {
                setNotification(false);
            }, 7000);
        }
    }

    useEffect(() => {
        //Getting All apps
        getApps();

            getAllUser(response => {
                setAllUsers(response.data)
                let users = response.data
                    .filter(user => user && user.username)
                    .map(user => user.username);
                users.sort();
                setUserList(users);
            })

            getAllGroups(response => {
                setAllGroups(response.data)
                let groups = response.data
                    .filter(group => group && group.groupname)
                    .map(group => group.groupname);
                groups.sort();
                setGroupList(groups);
            })



    }, []);

    function handleAddUserToApp(){
        if (selectedAppId && selectedAppId !== 0 && selectedUserId && selectedUserId !== 0){

        }else {
            console.log("No App selected to Add User")
        }
    }

    function handleAddGroupToApp(){
        if (selectedAppId && selectedAppId !== 0 && selectedGroupId && selectedGroupId !== 0){

        }else {
            console.log("No App selected to Add Group")
        }
    }

    function handleGroupDropdownChange(e){

        let groups = allGroups.filter( group => group && group.groupname === e.target.value)
        console.log("SelectedGroupId : ",groups && groups[0]?.id )
        setSelectedGroupId(groups && groups[0]?.id)
    }

    function handleUserDropdownChange(e){

        let users = allUsers.filter( user => user && user.username === e.target.value)
        console.log("SelectedUserId : ",users && users[0]?.id )
        setSelectedGroupId(users && users[0]?.id)
    }


    // Function to handle grid List clicks
    function handleGridRowClick(e) {

        getAppAccessList(e.dataItem.appid, result => {
            if (result.status !== "success"){
                // alert(`Status : ${data.status}, Message:${data.message}`)
                showNotification(" Error: "+result.message,"error")
                setSelectedAppId(0)
            }else{
                setSelectedAppId(e.dataItem.appid)
                setAccessMembers(result.data)

            }
        })

    };

    // Function to getAll Apps
    function getApps() {
        axios.post("/php/calldb.php", `action=getapps`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        })
            .then(function (response) {
                const data = response.data;
                setAllApps(data)
                console.log('getAllApps -> ', data);
            })
    }


  return (
    <div className="dcom-setting-body">

        {/*  Dialogue Container  */}
          <NotificationGroup style={{
              right: "10px", bottom: 0,
              alignItems: "flex-start",
              flexWrap: "wrap-reverse",
          }}>
            <Fade enter={true} exit={true}>
                {notification && (
                    <Notification
                        type={{ style:`${notificationType}`, icon: true }}
                        closable={true}
                        onClose={() => setNotification(false)}
                    >
                        <span>{notificationMsg}</span>
                    </Notification>
                )}
            </Fade>
        </NotificationGroup>

        <h1 style={{padding: "25px"}}>Applications</h1>

        <div className="dcom-user-settings-wrapper">
                <div className="dcom-user-data-grid">
                    <Grid
                        style={{
                            maxHeight: "400px"
                        }}
                        onRowClick={handleGridRowClick}

                        // data={data.data}
                        data={allApps.data}
                        skip={page.skip}
                        take={page.take}
                        total={allApps.data.length}
                        pageable={true}
                        onPageChange={pageChange}
                    >
                        {/*<Column field="id" title="ID"  />*/}
                        <Column field="title" title="Applications"/>
                    </Grid>
                </div>
        </div>
        <hr/>
        <div className="dcom-user-data-grid">
            {/*<h1 style={{padding: "25px"}}>Applications Access {selectedAppId === 0 ? "" : `for ${selectedAppId}`}</h1>*/}
            <h3 style={{padding: "25px"}} >Applications Access {selectedAppId === 0 ? "" : `for ${selectedAppId}`}</h3>
            <div
                style={{paddingLeft:"25px"}}
                className="buttonWrapper">
                <button
                    className="k-button k-outline k-button-icontext dcom-button"
                    type="button"
                    onClick={toggleAddToUser}
                >
                    {addUser ? "Cancel" : "Add to User"}
                </button>
                <button
                    className="k-button k-outline k-button-icontext dcom-button"
                    type="button"
                    onClick={toggleAddToGroup}
                >
                    {addGroup ? "Cancel" : "Add to Group"}
                </button>
                <button
                    className="k-button k-outline k-button-icontext dcom-button" type="button">
                    Remove Access
                </button>

                {
                    addUser &&
                    <div className="k-form-buttons">
                        <DropDownList
                            style={{marginTop:10, maxWidth:185}}
                            className="gridHeaderFilter"
                            defaultItem={"Select User . ."}
                            onChange={handleUserDropdownChange}
                            data={userList}/>
                        <button
                            className="k-button dcom-button-long"
                            onClick={handleAddUserToApp}>
                            Add User
                        </button>
                    </div>
                }
                {
                    addGroup &&
                    <div className="k-form-buttons">
                        <DropDownList
                            style={{marginTop:10, maxWidth:185}}
                            className="gridHeaderFilter"
                            defaultItem={"Select Group . ."}
                            onChange={handleGroupDropdownChange}
                            data={groupList}/>
                        <button
                            className="k-button dcom-button-long"
                            onClick={handleAddGroupToApp}>
                            Add Group
                        </button>
                    </div>
                }

            </div>
            <Grid
                style={{
                    maxHeight: "350px",
                }}
                onRowClick={handleGridRowClick}

                data={accessMembers && accessMembers}
                // data={AllUser.data.map((item) => ({
                //     ...item,
                //     [SELECTED_FIELD]: selectedState[idGetter(item)],
                // }))}
                skip={page.skip}
                take={page.take}
                total={accessMembers.data.length}
                pageable={true}
                onPageChange={pageChange}
            >
                {/*<Column field="id" title="ID"  />*/}
                <Column field="appaccess" title="Application Access"/>
            </Grid>
        </div>

    </div>
  )
}
