import React from 'react'
import {Button, Container, Row, Col, Form, Alert } from "react-bootstrap";
import axios from 'axios';
import  { useState } from 'react';

export default function Login({setLoginState,...rest}) {

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [errorMess, setErrorMess] = useState("");
    
    //Login OnClick
    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.elements.email.value === ""){
            setErrorMess("Email field empty. Please try again")
            setShow(true);
            event.preventDefault();
            event.stopPropagation();
        }else if (form.elements.password.value === ""){
            setErrorMess("Password field empty. Please try again")
            setShow(true);
            event.preventDefault();
        }
        else {
            event.preventDefault();
            doLogin(form.elements.email.value, form.elements.password.value);
        }    
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }
        // setValidated(true);
    };

    //Forget Password OnClick
    function forgetPassword(){
        alert("Do you want to reset your password ?")
    }

    function doLogin(username,password) {
        axios.post("/php/login2.php", `user=${username}&pwd=${password}`,  {
            headers: { 
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          } )
            .then(function(response) {
              if(response.data.loggedIn === true) setLoginState(true);
              else {
                setErrorMess(response.data.message);
                setShow(true);
              }    
              
            }).catch(function(error) {
                console.log(error);
              
            })
    }

    return (
        <>
            <div className="dcom-login-body">
                <div className="dcom-login-header">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col
                                style={{textAlign: "center"}}
                                md="auto">
                                <h1>Sign In</h1>
                                <p> Sign in to your Dcom account here</p>

                                <Alert show={show} variant="danger">
                                    {errorMess}
                                </Alert>

                                <div className="dcom-login-form">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Enter email"/>
                                            <Form.Control.Feedback type="invalid">
                                                Incorrect Email
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Password"/>
                                            <a className="dcom-login-forget-pass">
                                                <span
                                                onClick={forgetPassword}
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                                >Forget Password ?</span>
                                            </a>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid password
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                label="Remember"/>
                                        </Form.Group>
                                        <Button variant="primary"
                                                size="lg"
                                                type="submit"
                                                block>
                                            Login
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
