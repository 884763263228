import React, { useEffect, useState } from "react";
import {
  getAllIndex,
  updateSolr,
  getAllUser,
  getLastUpdatedLogs,
} from "./ApiCalls";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Modal, Button } from "react-bootstrap";
import { getter } from "@progress/kendo-react-common";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

export default function Index() {
  let [allIndex, setAllIndex] = useState({ data: [] });
  let [lastUpdatedLog, setLastUpdatedLog] = useState();
  let [lastUpdatedTime, setLastUpdatedTime] = useState();
  let [currentSelectedId, setCurrentSelectedId] = useState();

  let [notification, setNotification] = useState(false);
  let [notificationMsg, setNotificationMsg] = useState("Attention !");
  let [notificationType, setNotificationType] = useState("Success");

  const [show, setShow] = useState(false);

  const DATA_ITEM_KEY = "appid";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  //Grid selection hooks
  const [selectedState, setSelectedState] = React.useState({});

  function showNotification(message, notificationType) {
    setNotificationType(notificationType);
    setNotificationMsg(message);
    setNotification(!notification);
    if (!notification) {
      setTimeout(() => {
        setNotification(false);
      }, 7000);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // Function to handle grid List clicks
  function handleGridRowClick(e) {}

  function handleLastUpdateLog() {
    getLastUpdatedLogs((response) => {
      setLastUpdatedLog(response.data);
      setShow(true);
    });
  }

  // Function to get the value of the cell
  const cellWithBackGround = (props) => {
    const item = props.dataItem.unindexed;

    let solrInfo;
    switch (item) {
      case 0:
        solrInfo = <p style={{ color: "red" }}>INDEXING REQUESTED</p>;
        break;
      case 1:
        solrInfo = <p style={{ color: "green" }}>RUNNING</p>;
        break;
      case 2:
        solrInfo = <p style={{ color: "red" }}>ERROR</p>;
        break;
      case 3:
        solrInfo = <p style={{ color: "green" }}>WAITING FOR SCHEDULED RUN</p>;
        break;
    }

    return <td> {solrInfo || ""} </td>;
  };

  //Function to getAll indexes
  function getData() {
    getAllIndex((response) => {
      // console.log("User Data: ", response);
      setAllIndex(response);
      setLastUpdatedTime(response.index["indexLastUpdate"]);
    });
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    setCurrentSelectedId(Object.entries(newSelectedState)[0][0]);
  };

  function updateCurrentIndex() {
    updateSolr(currentSelectedId, (result) => {
      console.log(result);

      if (result.status !== "success") {
        // alert(`Status : ${data.status}, Message:${data.message}`)
        showNotification(" Error: " + result.message, "error");
        console.log(result.message);
      } else {
        showNotification(
          "Selected Index has been successfully updated !",
          "success"
        );
      }
    });
  }

  return (
    <div className="dcom-setting-body">
      {/*  Dialogue Container  */}
      <NotificationGroup
        style={{
          right: "10px",
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade enter={true} exit={true}>
          {notification && (
            <Notification
              type={{ style: `${notificationType}`, icon: true }}
              closable={true}
              onClose={() => setNotification(false)}
            >
              <span>{notificationMsg}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>

      {/* Modal to show last updated logs*/}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Last Updated Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          dangerouslySetInnerHTML={{ __html: lastUpdatedLog }}
        ></Modal.Body>
        <Button onClick={() => setShow(false)} variant="primary">
          Close
        </Button>
      </Modal>

      <h1 style={{ padding: "25px" }}>Index </h1>
      <div className="dcom-user-settings-wrapper">
        <div className="dcom-user-data-grid">
          <div style={{ paddingLeft: "25px" }} className="buttonWrapper">
            <button
              className="k-button k-outline k-button-icontext dcom-button"
              type="button"
            >
              <span role="presentation"></span>Refresh View
            </button>
            <button
              onClick={handleLastUpdateLog}
              className="k-button k-outline k-button-icontext dcom-button"
              type="button"
            >
              <span role="presentation"></span>View Last Update Log
            </button>

            {/* Reindex all Documents */}
            <button
              onClick={handleLastUpdateLog}
              className="k-button k-outline k-button-icontext dcom-button"
              type="button"
            >
              <span role="presentation"></span>Reindex All Documents
            </button>

            {/* Reindex selected Item */}
            {currentSelectedId && (
              <button
                className="k-button k-outline k-button-icontext dcom-button"
                onClick={updateCurrentIndex}
                type="button"
              >
                <span role="presentation"></span>Update Selected Index
              </button>
            )}
          </div>
          <Grid
            style={{ maxHeight: "780px" }}
            onRowClick={handleGridRowClick}
            data={allIndex.data.map((e) => ({
              ...e,
              [SELECTED_FIELD]: selectedState[idGetter(e)],
            }))}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              mode: "single",
            }}
            onSelectionChange={onSelectionChange}
          >
            <Column field="title" title="Application" />
            <Column field="appid" title="Replica ID" />
            <Column field="docExportedCount" title="Documents" />
            <Column field="total" title="Exported" />
            <Column
              field="unindexed"
              cell={cellWithBackGround}
              title="UnIndexed"
            />
          </Grid>
          {allIndex && (
            <p style={{ padding: "25px" }}>
              {" "}
              Last Update :{" "}
              <span style={{ color: "green" }}>{lastUpdatedTime}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
