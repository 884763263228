import React, {useEffect, useState} from 'react'
import {ListGroup } from "react-bootstrap";
export default function About(props) {
    return (
        <div>
            <div className="dcom-setting-body">
                <h1
                    style={{
                        margin:"25px",
                        paddingTop: "25px"}}
                >{props.title} About DCOM
                </h1>
                <div className="dcom-about-settings-wrapper">
                    <div style={{
                        borderRadius: "18px",
                        boxShadow: "5px 10px 18px #cfcccc",
                        textAlign:"left",
                        padding:"10px"
                    }}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Dcom Viewer Version: 3.4</ListGroup.Item>
                        <ListGroup.Item>License: Enterprise Edition</ListGroup.Item>
                        <ListGroup.Item>Website: http://www.rivit.ca</ListGroup.Item>
                        <ListGroup.Item>Support Email: support@rivit.ca</ListGroup.Item>
                        <ListGroup.Item>Copyright: Rivit Technology Partners � 2014-2021</ListGroup.Item>
                    </ListGroup>
                    </div>
                </div>
            </div>
            )
        </div>
    )
}