import React from "react";
import axios from "axios";

export function getAllGroups(callBack) {
  axios
    .post("/php/calldb.php", `action=getgroups`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

export function getAllUser(callBack) {
  axios
    .post("/php/calldb.php", `action=getusers`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

// Add User to Group
export function addUserToGroup(userId, GroupId, callBack) {
  axios
    .post(
      "/php/calldb.php",
      `action=adduser&userid=${userId}&parentid=${GroupId}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    )
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

// Add Group to Group MemberShip
export function addGroupToGroup(groupid, parentid, callBack) {
  axios
    .post(
      "/php/calldb.php",
      `action=addgroup&groupid=${groupid}&parentid=${parentid}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    )
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

// Remove User from Group
export function removeUserFromGroup(userId, GroupId, callBack) {
  axios
    .post(
      "/php/calldb.php",
      `action=removeuser&userid=${userId}&groupid=${GroupId}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    )
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

export function getAppAccessList(appId, callBack) {
  axios
    .post("/php/calldb.php", `action=getappmembers&appid=${appId}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

// Getting gridData using ViewId and app Id
export function getCurrentGrid(appId, viewId, callBack) {
  axios
    .get(`/php/getxml.php?file=\/${appId}\/views\/${viewId}.xml`, {
      headers: {
        "Content-Type": "text/xml",
      },
    })
    .then(function (response) {
      callBack(response);
    });
}

// Get call to fetch all index
export function getAllIndex(callBack) {
  axios
    .post("/php/calldb.php", `action=getsolr`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}

// Get Lastupdated logs in html for Index
export function getLastUpdatedLogs(callBack) {
  axios
    .get(`http://dev1.rivit.ca/log/log.html`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response;
      callBack(data);
    });
}

export function updateSolr(appId, callBack) {
  axios
    .post("/php/calldb.php", `action=updatesolr&appid=${appId}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
    .then(function (response) {
      const data = response.data;
      callBack(data);
    });
}
