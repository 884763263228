import * as React from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { withRouter } from "react-router-dom";

const paths = [
  { path: "/", index: ".0" },
  { path: "/groups", index: ".1" },
  { path: "/about", index: ".2" },
  { path: "/index", index: ".3" },
  { path: "/application", index: ".2.0" },
];

class PanelBarNavContainer extends React.Component {
  onSelect = (event) => {
    this.props.history.push(event.target.props.route);
  };

  setSelectedIndex = (pathName) => {
    let currentPath = paths.find((item) => item.path === pathName);
    return currentPath.index;
  };

  render() {
    let selected = this.setSelectedIndex(this.props.location.pathname);
    return (
      <div
        style={{
          "min-width": "320px",
          "min-height": "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            float: "left",
            "min-height": "100%",
            "min-width": "320px",
            backgroundColor: "#00205b",
          }}
        >
          <PanelBar
            selected={selected}
            expandMode={"single"}
            onSelect={this.onSelect}
          >
            <PanelBarItem title={"Settings"}>
              <PanelBarItem title={"Users"} route="/" />
              <PanelBarItem title={"Groups"} route="/groups" />
              <PanelBarItem title={"Applications"} route="/application" />
              <PanelBarItem title={"About"} route="/about" />
              <PanelBarItem title={"Index"} route="/index" />
            </PanelBarItem>
          </PanelBar>
        </div>
        <div
          style={{
            paddingLeft: "10pt",
            width: "90%",
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRouter(PanelBarNavContainer);
