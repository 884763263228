import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import axios from "axios";
import { getter } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  addUserToGroup,
  getAllGroups,
  getAllUser,
  removeUserFromGroup,
} from "./ApiCalls";

export default function UserSettings(props) {
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const emailValidator = (value) =>
    emailRegex.test(value) ? "" : "Please enter a valid email.";
  const EmailInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };
  const NameInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };

  let [AllUser, setAllUser] = useState({ data: [] });
  let [userGroup, setUserGroup] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(0);
  let [groupList, setGroupList] = useState([]);
  let [isForm, setIsForm] = useState(false);
  let [addToGroup, setAddtoGroup] = useState(false);
  let [isCreateNew, setCreateNew] = useState(false);
  let [notification, setNotification] = useState(false);
  let [notificationMsg, setNotificationMsg] = useState("Attention !");
  let [notificationType, setNotificationType] = useState("Success");
  let [userId, setUserId] = useState({});

  const initialDataState = { skip: 0, take: 10 };
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };
  const [visible, setVisible] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const SELECTED_FIELD = "selected";
  const DATA_ITEM_KEY = "groupname";
  const idGetter = getter(DATA_ITEM_KEY);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  function toggleAddToGroup() {
    setAddtoGroup(!addToGroup);
  }

  function showNotification(message, notificationType) {
    setNotificationType(notificationType);
    setNotificationMsg(message);
    setNotification(!notification);
    if (!notification) {
      setTimeout(() => {
        setNotification(false);
      }, 7000);
    }
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  useEffect(() => {
    getUser();
    getAllGroups((response) => {
      let groups = response.data
        .filter((group) => group && group.groupname)
        .map((group) => group.groupname);
      groups.sort();
      setGroupList(groups);
    });
  }, []);

  // Function to handle grid List clicks
  function handleGridRowClick(e) {
    setUserId(e.dataItem.id);
    setIsForm(true);
    setUser({
      firstName: e.dataItem.firstname,
      lastName: e.dataItem.lastname,
      username: e.dataItem.username,
      password: "",
      confirmpassword: "",
      email: e.dataItem.email,
      isadmin: e.dataItem.isadmin,
    });

    if (e.dataItem.id) {
      getUserGroup(e.dataItem.id);
    }
  }

  const [user, setUser] = React.useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    confirmpassword: "",
    email: "",
    isadmin: "0",
  });

  // Function to get Username from all usersApi
  function getUserName() {
    axios
      .post("/php/calldb.php", `action=getusers`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        const data = response.data;
        setAllUser(data);
        let users = data.data
          .filter((user) => user && user.username)
          .map((user) => user.username);
        users.sort();
        setGroupList(users);
      });
  }

  //Function to getAll users and store it in api
  function getUser() {
    getAllUser((response) => {
      console.log("User Data: ", response);
      setAllUser(response);
    });
  }

  // Function to Create Users initiated from update user button
  function getUserGroup(userid) {
    axios
      .post("/php/calldb.php", `action=getusergroups&userid=${userid}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        const data = response.data.data;
        setUserGroup(data);
      });
  }

  // Function to Create Users initiated from handleSubmit
  function createUser(username, password, firstname, lastname, email) {
    axios
      .post(
        "/php/calldb.php",
        `action=createuser&username=${username}&password=${password}&firstname=${firstname}&lastname=${lastname}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then(function (response) {
        const data = response.data;
        if (data.status === "success") {
          showNotification("User created Successfully !", "success");
          getUser();
          handleBack();
        } else {
          // alert(`Status : ${data.status}, Message:${data.message}`)
          showNotification(" Error: " + data.message, "error");
          handleBack();
        }
      });
  }

  // Function to Delete Users
  function deleteUser() {
    setVisible(!visible);
    axios
      .post("/php/calldb.php", `action=deleteuser&userid=${userId}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (response) {
        const data = response.data;
        if (data.status === "success") {
          // alert(`Status : ${data.status} !`)
          getUser();
          showNotification(`User successfully Deleted`, "success");
          handleBack();
        } else {
          // alert(`Status : ${data.status}, Message:${data.message}`)
          showNotification(
            `Status : ${data.status} , Message: ${data.message}`,
            "error"
          );
          handleBack();
        }
      });
  }

  // Submit button of the Create user form
  const handleSubmit = (dataItem) => {
    // createUser(dataItem.username,
    //     dataItem.password,
    //     dataItem.firstName,
    //     dataItem.lastName,
    //     dataItem.email)

    console.log("dataItem", dataItem);
  };

  //Handle Create new
  function handleCreate() {
    setCreateNew(true);
    setIsForm(true);
    setUser({
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmpassword: "",
      email: "",
    });
  }

  //Dropdown handle
  function handleDropdownChange(e) {
    getAllGroups((response) => {
      let groups = response.data.filter(
        (group) => group && group.groupname === e.target.value
      );
      setSelectedGroupId(groups && groups[0]?.id);
    });
  }

  // Submit button of the Create user form
  const handleDelete = () => {
    if (userId) deleteUser();
  };

  // Submit button of the Create user form
  const handleBack = () => {
    setIsForm(false);
    setCreateNew(false);
    setAddtoGroup(false);
  };

  //Handle AddUserToGroup from the Dropdown
  function handleAddUserToGroup() {
    if (selectedGroupId && userId) {
      addUserToGroup(userId, selectedGroupId, (result) => {
        if (result.status === "success") {
          // alert(`Status : ${data.status} !`)
          getUser();
          showNotification(`User successfully added to Group !`, "success");
          handleBack();
        } else {
          // alert(`Status : ${data.status}, Message:${data.message}`)
          showNotification(
            `Status : ${result.status} , Message: ${result.message}`,
            "error"
          );
          handleBack();
        }
      });
    }
  }

  // Remove User from Group
  function handleRemoveFromGroup() {
    getAllGroups((result) => {
      console.log(result);
      let groupId = result.data.filter(
        (e) => e && e.groupname === Object.entries(selectedState)[0][0]
      );

      removeUserFromGroup(userId, groupId[0].id, (result) => {
        console.log(result);
        if (result.status === "success") {
          getUser();
          showNotification(`User successfully added to Group !`, "success");
          handleBack();
        } else {
          // alert(`Status : ${data.status}, Message:${data.message}`)
          showNotification(
            `Status : ${result.status} , Message: ${result.message}`,
            "error"
          );
          handleBack();
        }
      });
    });
  }

  return (
    <div className="dcom-setting-body">
      {/*  Dialogue Container  */}
      <NotificationGroup
        style={{
          right: "10px",
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade enter={true} exit={true}>
          {notification && (
            <Notification
              type={{ style: `${notificationType}`, icon: true }}
              closable={true}
              onClose={() => setNotification(false)}
            >
              <span>{notificationMsg}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>

      {/*  Dialogue Box  */}
      {visible && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Are you sure you want to delete the user ?
          </p>
          <DialogActionsBar>
            <button className="k-button" onClick={toggleDialog}>
              No
            </button>
            <button className="k-button" onClick={handleDelete}>
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      <h1 style={{ padding: "25px" }}>{props.title} Users </h1>
      <div className="dcom-user-settings-wrapper">
        {isForm ? (
          <div className="dcom-user-form-wrapper">
            <div className="buttonWrapper">
              <button
                onClick={handleBack}
                className="k-button k-outline k-button-icontext dcom-button"
                type="button"
              >
                <span role="presentation"></span>Back
              </button>
            </div>
            <Form
              onSubmit={handleSubmit}
              initialValues={user}
              key={JSON.stringify(user)}
              className="dcom-user-form"
              render={(formRenderProps) => (
                <FormElement>
                  <fieldset className={"k-form-fieldset"}>
                    <legend className={"k-form-legend"}>
                      Please fill in the fields:
                    </legend>

                    <div className="row">
                      <div className="col">
                        {/* First Name in the form */}
                        <div className="mb-4">
                          <Field
                            name={"firstName"}
                            component={Input}
                            label={"First name"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        {/* Last Name in the form */}
                        <div className="mb-4">
                          <Field
                            name={"lastName"}
                            component={Input}
                            label={"Last name"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        {/*Username in the form */}
                        <div className="mb-4">
                          <Field
                            name={"username"}
                            component={Input}
                            label={"Username"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {/* Password */}
                        <div className="mb-4">
                          <Field
                            component={Input}
                            name="password"
                            type="password"
                            label={"Password"}
                          />
                        </div>
                      </div>

                      <div className="col">
                        {/* Confirm Password  */}

                        <div className="mb-4">
                          <Field
                            component={Input}
                            name="confirmpassword"
                            type="password"
                            label={"Confirm Password"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        {/* Email  */}
                        <div className="mb-4">
                          <Field
                            name={"email"}
                            type={"email"}
                            component={EmailInput}
                            label={"Email"}
                            validator={emailValidator}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <Checkbox
                      defaultChecked={false}
                      name={"isadmin"}
                      value={"isadmin"}
                      label={"Admin"}
                    />
                  </fieldset>
                  <div className="k-form-buttons">
                    <button
                      type={"submit"}
                      className="k-button dcom-button"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {isCreateNew ? "Create User" : "Update User"}
                    </button>
                    {!isCreateNew && (
                      <button
                        className="k-button dcom-button"
                        onClick={toggleDialog}
                      >
                        Delete User
                      </button>
                    )}
                  </div>
                </FormElement>
              )}
            />
            <hr />
            {!isCreateNew && (
              <>
                <h3>Mermbership in Groups</h3>
                <div className="k-form-buttons">
                  <button
                    type={"submit"}
                    className="k-button dcom-button-long"
                    onClick={toggleAddToGroup}
                  >
                    {addToGroup ? "Cancel" : "Add to Group"}
                  </button>
                  {selectedState && (
                    <button
                      onClick={handleRemoveFromGroup}
                      type={"submit"}
                      className="k-button dcom-button-long"
                    >
                      Remove from Group
                    </button>
                  )}
                </div>
                {addToGroup && (
                  <div className="k-form-buttons">
                    <DropDownList
                      style={{ marginTop: 10, maxWidth: 145 }}
                      className="gridHeaderFilter"
                      defaultItem={"Select Group . ."}
                      onChange={handleDropdownChange}
                      data={groupList}
                    />
                    <button
                      className="k-button dcom-button-long"
                      onClick={handleAddUserToGroup}
                    >
                      Add
                    </button>
                  </div>
                )}
                <Grid
                  style={{ maxHeight: "250px" }}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                  }}
                  data={userGroup.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  }))}
                  onSelectionChange={onSelectionChange}
                >
                  <Column field="groupname" title="Group Name" />
                </Grid>
              </>
            )}
          </div>
        ) : (
          <div className="dcom-user-data-grid">
            <div style={{ paddingLeft: "25px" }} className="buttonWrapper">
              <button
                className="k-button k-outline k-button-icontext dcom-button"
                type="button"
                onClick={getUser}
              >
                <span role="presentation"></span>Refresh
              </button>
              <button
                onClick={handleCreate}
                className="k-button k-outline k-button-icontext dcom-button"
                type="button"
              >
                <span role="presentation"></span>Create User
              </button>
            </div>
            <Grid
              style={{ maxHeight: "670px" }}
              onRowClick={handleGridRowClick}
              data={AllUser}
            >
              <Column field="username" title="Username" />
              <Column field="firstname" title="First Name" />
              <Column field="lastname" title="Last Name" />
              <Column field="email" title="Email" />
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
